import { Calendar } from '@fullcalendar/core'
import zhLocale from '@fullcalendar/core/locales/zh-cn'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import iCalendarPlugin from '@fullcalendar/icalendar'
import tippy from 'tippy.js';
import './static/css/icmcalendar.css'
import 'tippy.js/dist/tippy.css';

document.addEventListener('DOMContentLoaded', function () {
  var initialLocaleCode = 'en';
  var localeSelectorEl = document.getElementById('locale-selector');
  var calendarEl = document.getElementById('calendar')

  var calendar = new Calendar(calendarEl, {
    timezone: 'Asia/Shanghai',
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
      iCalendarPlugin,
    ],
    contentHeight: 'auto',
    // aspectRatio: 3,
	expandRows: true,
    locales: [zhLocale],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,listMonth',
    },
    initialView: 'dayGridMonth',
    weekNumbers: true,	
	nextDayThreshold: '08:00:00',
	slotMinTime: '08:00:00',
	slotMaxTime: '23:00:00',
    navLinks: true, // can click day/week names to navigate views
    editable: true,
    dayMaxEvents: false, // allow "more" link when too many events
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short',
    },
    eventSources: [
    {
      url: '/feed/colloquium/',
      format: 'ics',
      display: 'list-item',
      backgroundColor: 'darkblue',
      borderColor: 'darkblue'
    },
    {
      url: '/feed/conference/',
      format: 'ics',
      display: 'list-item',
      backgroundColor: 'darkgreen',
      borderColor: 'darkgreen'
    },
    {
      url: '/feed/minicourse/',
      format: 'ics',
      display: 'list-item',
      backgroundColor: 'olive',
      borderColor: 'olive'
    },
    {
      url: '/feed/seminar/',
      format: 'ics',
      display: 'list-item',
      backgroundColor: 'violet',
      borderColor: 'violet'
    },
    ],

    eventClick: function(info) {
      info.jsEvent.preventDefault(); // don't let the browser navigate
  
      if (info.event.url) {
        window.open(info.event.url);
      }
    },


    // 鼠标进入事件时显示详情
    eventMouseEnter: function (info) {
      tippy(info.el, {
        content: "<div style='width: 20rem; max-height: 30rem; overflow:hidden; text-overflow: ellipsis;'>" +
          "<div style='border-bottom: 0.01rem solid #CCCCCC;min-height: 1rem;color: white; text-align: left;font-weight:bold;'>" + info.event.title + "</div>" +
          "<div style='color: white;'>Start Time：<i style='color: lightgrey;'>" + info.event.startStr + "</i></div>" +
          "<div style='color: white;'>End Time：<i style='color: lightgrey;'>" + info.event.endStr + "</i></div>" +
          "<div style='color: white;'>Speaker(s)：<i style='color: lightgrey;'>" + info.event.extendedProps.organizer+ "</i></div>" +
          "<div style='color: white;'>Location：<i style='color: lightgrey;'>" + info.event.extendedProps.location + "</i></div>" + 
          "<div style='color: white;'>Abstract：<i style='color: lightgrey; '>" + info.event.extendedProps.description + "</i></div>" +
          "</div>",
        theme: 'light',
        interactive: true,
        placement: 'right-end',
        allowHTML: true,
      });

    },

  })
  calendar.setOption('locale', 'en');
  calendar.render()
  calendar.getAvailableLocaleCodes().forEach(function (localeCode) {
    var optionEl = document.createElement('option');
    optionEl.value = localeCode;
    optionEl.selected = localeCode == initialLocaleCode;
    optionEl.innerText = localeCode;
    localeSelectorEl.appendChild(optionEl);
  });

  // when the selected option changes, dynamically change the calendar option
  localeSelectorEl.addEventListener('change', function () {
    if (this.value) {
      calendar.setOption('locale', this.value);
    }
  });
})
